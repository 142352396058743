<template>
  <v-tabs optional align-with-title :show-arrows="$vuetify.breakpoint.smAndUp" v-model="tab" class="categoryTabs">
    <v-tab v-for="category in categories" v-bind:key="category.id" @click="scrollTo(category.id)">{{ category.name }}
    </v-tab>
  </v-tabs>
</template>

<script>

import {mapGetters} from "vuex";

export default {
  name: "ScrollableCategories",
  data() {
    return {
      tab: undefined
    }
  },
  props: {
    categories: Array
  },
  computed: {
    ...mapGetters({
      categoryInFocus: "menuStore/getCategoryInFocus"
    })
  },
  methods: {
    scrollTo(category) {
      this.$store.commit('menuStore/SET_CATEGORY_TO_FOCUS', category);
    }
  },
  watch: {
    categoryInFocus(value) {
      let index = this.categories.findIndex(cat => cat.name === value)
      if (index !== -1) {
        this.tab = index
      }
    }
  },
}
</script>

<style lang="sass">
@import '~vuetify/src/styles/settings/_variables'
//This is a fix for a bug(?) in vuetify where there is an empty space in front of the scrollable items if arrows disabled
.v-slide-group__prev
  @media #{map-get($display-breakpoints, 'xs-only')}
    display: none !important

.v-tab
  @media #{map-get($display-breakpoints, 'xs-only')}
    font-size: 12px!important
    padding-left: 9px!important
    padding-right: 9px!important


</style>
